<template>
  <v-container
    id="accueil"
    fluid
    tag="section"
  >
    <base-material-card
      color="success"
      icon="mdi-newspaper-variant-outline"
      inline
      :title="evenement.nom"
      class="px-5 py-3 mb-5"
    >
      <div
        class="mt-2"
        v-html="evenement.infoProgramme"
      />
    </base-material-card>
  </v-container>
</template>

<script>

  import restApiService from '@/services/restApiService.js'

  export default {
    name: 'Accueil',

    data: () => ({
      evenement: {},
    }),
    created () {
      restApiService.get(`/api/evenements/${this.$route.params.idEvenement}`)
        .then((result) => {
          this.evenement = result.data
        })
        .catch((erreur) => alert(erreur))
    },
  }
</script>
